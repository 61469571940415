/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiArrowLeftSLine, RiFileDownloadLine, RiPrinterLine, RiMailLine, RiHeartLine, RiTeamLine, RiUserVoiceLine } from "react-icons/ri"

const DiversityInclusionPage = () => {
  return (
    <Layout>
      <SEO 
        title="Diversity & Inclusion Policy Template | DEI Guidelines"
        description="Download our customizable diversity, equity and inclusion policy template with implementation strategies and best practices for creating an inclusive workplace."
        keywords={[
          "diversity policy template",
          "inclusion guidelines",
          "DEI policy",
          "workplace equity policy",
          "anti-discrimination policy",
          "inclusive workplace guidelines",
          "diversity best practices"
        ]}
      />
      
      {/* Hero Section */}
      <section sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 800,
              mb: 3,
              color: 'primary'
            }}>
              Diversity & Inclusion Policy Template
            </h1>
            
            <p sx={{
              fontSize: ['1.1rem', '1.25rem'],
              maxWidth: '800px',
              mb: 4,
              lineHeight: 1.5,
              color: 'text'
            }}>
              A comprehensive, customizable template to establish guidelines for creating 
              and maintaining a diverse, equitable, and inclusive workplace.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
              <Link to="#download" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  color: 'white'
                }
              }}>
                Download Template <RiFileDownloadLine />
              </Link>
              
              <button type="button" onClick={() => window.print()} sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                border: 'none',
                cursor: 'pointer',
                boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}>
                Print Preview <RiPrinterLine />
              </button>
            </div>
          </div>
        </div>
      </section>
      
      {/* Main Content */}
      <section sx={{
        py: 5,
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1080px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '280px 1fr'],
          gap: 4
        }}>
          {/* Table of Contents Sidebar */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            alignSelf: 'start',
            position: ['static', 'static', 'sticky'],
            top: '20px',
            display: ['none', 'none', 'block']
          }}>
            <h3 sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              mb: 3,
              color: 'primary'
            }}>
              Table of Contents
            </h3>
            
            <nav>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                <li sx={{ mb: 2 }}>
                  <Link to="#introduction" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Introduction
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#policy-statement" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Policy Statement
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#key-principles" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Key Principles
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#implementation" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Implementation Strategies
                  </Link>
                </li>
                <li sx={{ mb: 0 }}>
                  <Link to="#accountability" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Accountability & Reporting
                  </Link>
                </li>
              </ul>
            </nav>
            
            <div sx={{
              mt: 4,
              pt: 4,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="#download" sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiFileDownloadLine /> Download Template
              </Link>
            </div>
          </div>
          
          {/* Main Content Area */}
          <div>
            {/* Introduction Section */}
            <div id="introduction" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiHeartLine sx={{ color: 'primary' }} /> Introduction
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  A comprehensive diversity and inclusion policy is essential for creating a workplace 
                  where all employees feel valued, respected, and empowered to contribute their unique 
                  perspectives and talents. This policy template outlines key principles and practices 
                  for fostering diversity, equity, and inclusion (DEI) within your organization.
                </p>
                
                <p>
                  This template can be customized to align with your organization's specific values, 
                  industry context, and demographic makeup. It aims to help establish a framework for 
                  promoting diversity and inclusion at all levels of the organization.
                </p>
                
                <div sx={{
                  bg: 'primaryMuted',
                  p: 3,
                  borderRadius: '6px',
                  mt: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    Benefits of a Strong Diversity & Inclusion Policy:
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    }
                  }}>
                    <li>Enhanced innovation and creativity through diverse perspectives</li>
                    <li>Improved employee engagement, satisfaction, and retention</li>
                    <li>Broader talent attraction and acquisition capabilities</li>
                    <li>Better decision-making and problem-solving</li>
                    <li>Stronger connection with diverse customer bases</li>
                    <li>Reduced risk of discrimination and harassment complaints</li>
                    <li>Improved organizational reputation and brand image</li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Policy Statement Section */}
            <div id="policy-statement" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiTeamLine sx={{ color: 'primary' }} /> Policy Statement
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] is committed to fostering, cultivating, and preserving a culture of 
                  diversity, equity, and inclusion. We embrace and encourage our employees' differences 
                  in age, color, disability, ethnicity, family or marital status, gender identity or 
                  expression, language, national origin, physical and mental ability, political affiliation, 
                  race, religion, sexual orientation, socio-economic status, veteran status, and other 
                  characteristics that make our employees unique.
                </p>
                
                <p>
                  Our diversity initiatives are applicable—but not limited—to our practices and policies 
                  on recruitment and selection; compensation and benefits; professional development and 
                  training; promotions; transfers; social and recreational programs; layoffs; terminations; 
                  and the ongoing development of a work environment built on the premise of equity.
                </p>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Sample Definitions
                  </h4>
                  
                  <ul sx={{
                    pl: 0,
                    mb: 0,
                    listStyle: 'none',
                    '& li': {
                      mb: 2,
                      pb: 2,
                      borderBottom: '1px solid #e9ecef'
                    },
                    '& li:last-child': {
                      mb: 0,
                      pb: 0,
                      borderBottom: 'none'
                    }
                  }}>
                    <li>
                      <strong>Diversity:</strong> The presence of differences that make each person unique 
                      and that can be used to differentiate groups and people from one another.
                    </li>
                    <li>
                      <strong>Equity:</strong> The fair treatment, access, opportunity, and advancement 
                      for all people, while at the same time striving to identify and eliminate barriers 
                      that prevent the full participation of some groups.
                    </li>
                    <li>
                      <strong>Inclusion:</strong> The practice of ensuring that people feel a sense of 
                      belonging and support from the organization, and that their input is valued.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Key Principles Section */}
            <div id="key-principles" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiUserVoiceLine sx={{ color: 'primary' }} /> Key Principles
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name]'s diversity and inclusion initiatives are guided by the following 
                  key principles:
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Respect & Dignity
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      We treat all employees, customers, and stakeholders with respect and dignity. 
                      We expect all interactions to be free from bias, prejudice, discrimination, and harassment.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Equal Opportunity
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      We provide equal employment opportunities for all qualified individuals without 
                      regard to protected characteristics, focusing solely on merit and qualifications.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Inclusive Culture
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      We foster an inclusive work environment where all employees can participate and 
                      contribute, with a sense of belonging and psychological safety.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Continuous Improvement
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      We commit to ongoing assessment and enhancement of our diversity and inclusion 
                      practices, adapting to evolving needs and best practices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Implementation Strategies Section */}
            <div id="implementation" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  sx={{ color: 'primary' }}
                  aria-hidden="true"
                >
                  <title>Implementation icon</title>
                  <path d="M17 3a2.85 2.85 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
                  <path d="m15 5 4 4" />
                </svg> 
                Implementation Strategies
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] will implement this diversity and inclusion policy through various 
                  strategic initiatives and programs designed to create meaningful change throughout 
                  the organization. These strategies include:
                </p>
                
                <div sx={{
                  mt: 3,
                  display: 'grid',
                  gap: 4
                }}>
                  <div sx={{
                    bg: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                    overflow: 'hidden'
                  }}>
                    <div sx={{
                      bg: 'primary',
                      color: 'white',
                      p: 3,
                      fontWeight: 600,
                      fontSize: '1.1rem'
                    }}>
                      Recruitment & Hiring
                    </div>
                    <div sx={{ p: 3 }}>
                      <ul sx={{
                        pl: 4,
                        mb: 0,
                        '& li': {
                          mb: 2
                        },
                        '& li:last-child': {
                          mb: 0
                        }
                      }}>
                        <li>Use inclusive language in job descriptions and remove biased terminology</li>
                        <li>Implement blind resume screening to reduce unconscious bias</li>
                        <li>Establish diverse interview panels for candidate evaluation</li>
                        <li>Partner with organizations that support underrepresented groups</li>
                        <li>Set diversity goals for candidate pools and track progress</li>
                        <li>Train hiring managers on inclusive recruitment practices</li>
                      </ul>
                    </div>
                  </div>
                  
                  <div sx={{
                    bg: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                    overflow: 'hidden'
                  }}>
                    <div sx={{
                      bg: 'primary',
                      color: 'white',
                      p: 3,
                      fontWeight: 600,
                      fontSize: '1.1rem'
                    }}>
                      Training & Development
                    </div>
                    <div sx={{ p: 3 }}>
                      <ul sx={{
                        pl: 4,
                        mb: 0,
                        '& li': {
                          mb: 2
                        },
                        '& li:last-child': {
                          mb: 0
                        }
                      }}>
                        <li>Provide regular diversity, equity, and inclusion training for all employees</li>
                        <li>Offer unconscious bias training for managers and decision-makers</li>
                        <li>Develop cultural competence training for customer-facing roles</li>
                        <li>Create mentorship and sponsorship programs for underrepresented groups</li>
                        <li>Ensure equal access to professional development opportunities</li>
                        <li>Incorporate DEI principles into leadership development programs</li>
                      </ul>
                    </div>
                  </div>
                  
                  <div sx={{
                    bg: 'white',
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                    overflow: 'hidden'
                  }}>
                    <div sx={{
                      bg: 'primary',
                      color: 'white',
                      p: 3,
                      fontWeight: 600,
                      fontSize: '1.1rem'
                    }}>
                      Workplace Culture & Policies
                    </div>
                    <div sx={{ p: 3 }}>
                      <ul sx={{
                        pl: 4,
                        mb: 0,
                        '& li': {
                          mb: 2
                        },
                        '& li:last-child': {
                          mb: 0
                        }
                      }}>
                        <li>Create employee resource groups (ERGs) to support diverse communities</li>
                        <li>Establish flexible work arrangements to accommodate diverse needs</li>
                        <li>Review and update policies to remove potential barriers to inclusion</li>
                        <li>Celebrate cultural events and observances throughout the year</li>
                        <li>Encourage open dialogue about diversity and inclusion topics</li>
                        <li>Develop clear anti-discrimination and anti-harassment policies</li>
                      </ul>
                    </div>
                  </div>
                  
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                    gap: 3
                  }}>
                    <div sx={{
                      bg: 'primaryMuted',
                      p: 3,
                      borderRadius: '6px'
                    }}>
                      <h4 sx={{
                        fontSize: '1.1rem',
                        fontWeight: 600,
                        mb: 2,
                        color: 'primary'
                      }}>
                        Supplier Diversity
                      </h4>
                      
                      <p sx={{ mb: 0 }}>
                        Establish a supplier diversity program to increase procurement from businesses 
                        owned by underrepresented groups, set targets for diverse supplier engagement, 
                        and track spending with diverse suppliers.
                      </p>
                    </div>
                    
                    <div sx={{
                      bg: 'primaryMuted',
                      p: 3,
                      borderRadius: '6px'
                    }}>
                      <h4 sx={{
                        fontSize: '1.1rem',
                        fontWeight: 600,
                        mb: 2,
                        color: 'primary'
                      }}>
                        Community Engagement
                      </h4>
                      
                      <p sx={{ mb: 0 }}>
                        Partner with community organizations that support diversity initiatives, 
                        participate in outreach programs for underrepresented groups, and sponsor 
                        events that promote inclusion and equity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Accountability & Reporting Section */}
            <div id="accountability" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  sx={{ color: 'primary' }}
                  aria-hidden="true"
                >
                  <title>Accountability icon</title>
                  <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z" />
                  <line x1="4" x2="4" y1="22" y2="15" />
                </svg> 
                Accountability & Reporting
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] is committed to accountability and transparency in our diversity and 
                  inclusion efforts. We will regularly measure, evaluate, and report on our progress 
                  to ensure continuous improvement and meaningful results.
                </p>
                
                <div sx={{
                  mt: 4,
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
                  gap: 4
                }}>
                  <div>
                    <h3 sx={{
                      fontSize: '1.25rem',
                      fontWeight: 600,
                      mb: 3,
                      color: 'primary',
                      borderBottom: '2px solid',
                      borderColor: 'primaryLight',
                      pb: 2
                    }}>
                      Roles & Responsibilities
                    </h3>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 3
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>
                        <strong>Leadership Team:</strong> Provide strategic direction and resources for 
                        DEI initiatives, model inclusive behaviors, and hold managers accountable.
                      </li>
                      <li>
                        <strong>DEI Committee/Officer:</strong> Coordinate implementation of initiatives, 
                        develop metrics and reporting systems, and provide guidance on best practices.
                      </li>
                      <li>
                        <strong>Human Resources:</strong> Integrate DEI into HR processes, maintain 
                        demographic data, and support implementation of policy throughout employee lifecycle.
                      </li>
                      <li>
                        <strong>Managers:</strong> Implement DEI practices within teams, address bias or 
                        discrimination, and support inclusive team cultures.
                      </li>
                      <li>
                        <strong>All Employees:</strong> Practice inclusive behaviors, participate in 
                        training, and report concerns about potential violations of this policy.
                      </li>
                    </ul>
                  </div>
                  
                  <div>
                    <h3 sx={{
                      fontSize: '1.25rem',
                      fontWeight: 600,
                      mb: 3,
                      color: 'primary',
                      borderBottom: '2px solid',
                      borderColor: 'primaryLight',
                      pb: 2
                    }}>
                      Measurement & Reporting
                    </h3>
                    
                    <p>
                      We will track and regularly report on key diversity and inclusion metrics, 
                      including but not limited to:
                    </p>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 3,
                      '& li': {
                        mb: 2
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Workforce diversity statistics at all organizational levels</li>
                      <li>Recruitment and hiring metrics by demographic groups</li>
                      <li>Promotion and advancement rates across demographic groups</li>
                      <li>Pay equity analyses across gender, race, and other relevant factors</li>
                      <li>Employee engagement and inclusion survey results</li>
                      <li>Participation in training and development programs</li>
                      <li>Supplier diversity spending</li>
                    </ul>
                    
                    <div sx={{
                      bg: '#f8f9fa',
                      border: '1px solid #e9ecef',
                      borderRadius: '6px',
                      p: 3
                    }}>
                      <h4 sx={{
                        fontSize: '1.1rem',
                        fontWeight: 600,
                        mb: 2,
                        color: 'text',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                      }}>
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="20" 
                          height="20" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          strokeWidth="2" 
                          strokeLinecap="round" 
                          strokeLinejoin="round"
                          aria-hidden="true"
                        >
                          <title>Info icon</title>
                          <circle cx="12" cy="12" r="10" />
                          <line x1="12" x2="12" y1="8" y2="12" />
                          <line x1="12" x2="12.01" y1="16" y2="16" />
                        </svg>
                        Reporting Concerns
                      </h4>
                      
                      <p sx={{ mb: 0 }}>
                        Employees who believe they have experienced or witnessed discrimination, harassment, 
                        or other violations of this policy are encouraged to report their concerns through 
                        the following channels:
                      </p>
                      
                      <ul sx={{
                        pl: 4,
                        mt: 2,
                        mb: 0
                      }}>
                        <li>Direct supervisor or manager</li>
                        <li>Human Resources department</li>
                        <li>DEI Committee/Officer</li>
                        <li>Anonymous reporting hotline: [Phone/Email]</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Download Section */}
            <div id="download" sx={{
              bg: 'primaryMuted',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mt: 5
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Download Full Diversity & Inclusion Policy Template
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.6
              }}>
                Get the complete, customizable template with all sections to implement a comprehensive 
                diversity and inclusion policy for your organization. The full template includes:
              </p>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0,
                mb: 3,
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 2
              }}>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> MS Word & PDF formats
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Implementation guidelines
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> DEI assessment tools
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Training recommendations
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Metrics & KPI examples
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Legal compliance checklist
                </li>
              </ul>
              
              <div sx={{
                display: 'flex',
                gap: 3,
                flexWrap: 'wrap'
              }}>
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    color: 'white'
                  }
                }}>
                  Download Full Template <RiFileDownloadLine />
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  Request Customization <RiMailLine />
                </Link>
              </div>
            </div>
            
            {/* Navigation Links */}
            <div sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 4,
              pt: 3,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="/policies/workplace-safety" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiArrowLeftSLine /> Previous: Workplace Safety
              </Link>
              
              <Link to="/policies/family-leave" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                Next: Family & Parental Leave <RiArrowRightSLine />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DiversityInclusionPage 